import { ref } from 'valtio';

import { assertExhaustive } from './common';

export interface IStorage<K extends string, V> {
  set(key: K, val: V): void;

  get(key: K): V | null;

  remove(key: K): void;
}

export class InMemoryStorage<K extends string, V> implements IStorage<K, V> {
  private data: Record<K, V> = {} as Record<K, V>;

  set(key: K, val: V): void {
    this.data[key] = val;
  }

  get(key: K): V | null {
    return this.data[key] ?? null;
  }

  remove(key: K): void {
    delete this.data[key];
  }
}

export class LocalStorage<K extends string, V> implements IStorage<K, V> {
  set(key: K, val: V): void {
    localStorage.setItem(key, JSON.stringify(val));
  }

  get(key: K): V | null {
    const ret = localStorage.getItem(key);
    if (!ret) {
      return null;
    }
    return JSON.parse(ret) as V;
  }

  remove(key: K): void {
    localStorage.removeItem(key);
  }
}

export class RawLocalStorage<K extends string, V> implements IStorage<K, V> {
  set(key: K, val: V): void {
    if (typeof val !== 'string')
      throw new Error('RawLocalStorage only accepts string value');
    localStorage.setItem(key, val);
  }

  get(key: K): V | null {
    const ret = localStorage.getItem(key);
    if (!ret) {
      return null;
    }
    return ret as unknown as V;
  }

  remove(key: K): void {
    localStorage.removeItem(key);
  }
}

export class DummyStorage<K extends string, V> implements IStorage<K, V> {
  set(_key: K, _val: V): void {
    // Dummy set
  }

  get(_key: K): V | null {
    return null;
  }

  remove(_key: K): void {
    // Dummy get
  }
}

export class SessionStorage<K extends string, V> implements IStorage<K, V> {
  set(key: K, val: V): void {
    sessionStorage.setItem(key, JSON.stringify(val));
  }

  get(key: K): V | null {
    const ret = sessionStorage.getItem(key);
    if (!ret) {
      return null;
    }
    return JSON.parse(ret) as V;
  }

  remove(key: K): void {
    sessionStorage.removeItem(key);
  }
}

export type StorageType =
  | 'in-memory'
  | 'local'
  | 'dummy'
  | 'raw-local'
  | 'session';

export function StorageFactory<K extends string, V>(
  type: StorageType
): IStorage<K, V> {
  switch (type) {
    case 'in-memory':
      return new InMemoryStorage<K, V>();
    case 'local':
      return new LocalStorage<K, V>();
    case 'dummy':
      return new DummyStorage<K, V>();
    case 'raw-local':
      return new RawLocalStorage<K, V>();
    case 'session':
      return new SessionStorage<K, V>();
    default:
      assertExhaustive(type);
      throw new Error(`unknown storage type ${type}`);
  }
}

export class StorageStore<T> {
  static CreateVRefed<T>(key: string): StorageStore<T> {
    return ref(new StorageStore<T>(key));
  }
  constructor(
    private key: string,
    private storage: IStorage<string, T> = StorageFactory<string, T>('local')
  ) {}
  set<K extends keyof T>(key: K, val: T[K]): void {
    const data = this.storage.get(this.key) ?? ({} as T);
    data[key] = val;
    this.storage.set(this.key, data);
  }
  get<K extends keyof T>(key: K): T[K] | undefined {
    const data = this.storage.get(this.key) || ({} as T);
    return data[key];
  }
  remove(...keys: (keyof T)[]): void {
    const data = this.storage.get(this.key) ?? ({} as T);
    for (const key of keys) {
      delete data[key];
    }
    this.storage.set(this.key, data);
  }
}
